import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportesTabPage } from './reportes-tab.page';

const routes: Routes = [
  {
    path: '',
    component: ReportesTabPage,
    children:[
      {
        path:"mapa/:id/:inicial/:final",
        children:[
          {
            path:'',
            loadChildren: () => import('./mapa-reportes/mapa-reportes.module').then( m => m.MapaReportesPageModule )
          }
        ]
      },   
      {
        path:"registros",
        children:[
          {
            path:'',
            loadChildren: () => import('./reportes-registros/reportes-registros-routing.module').then( m => m.ReportesRegistrosPageRoutingModule )
          }
        ]
      },       
    ]
  },
  {
    path: '',    
    redirectTo: '/reportes-tab',
    // loadChildren: () => import('./cuenta/cuenta.module').then( m => m.CuentaPageModule),
    pathMatch: 'full'
  },
  {
    path: 'reportes-registros',
    loadChildren: () => import('./reportes-registros/reportes-registros.module').then( m => m.ReportesRegistrosPageModule)
  },
  
];

// const routes: Routes = [
//   {
//     path: '',
//     component: ReportesTabPage
//   },
//   {
//     path: 'mapa-reportes',
//     loadChildren: () => import('./mapa-reportes/mapa-reportes.module').then( m => m.MapaReportesPageModule)
//   }
// ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportesTabPageRoutingModule {}
