import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-reportes-tab',
  templateUrl: './reportes-tab.page.html',
  styleUrls: ['./reportes-tab.page.scss'],
})

export class ReportesTabPage implements OnInit {



  constructor( public router: Router, private _api:ApiService, public navCtrl:NavController,
                public loadingCtrl:LoadingController ) {    

                  
 
  }

  ngOnInit() {
    // setTimeout(() => {      
    //   this.leafletMap();      
    // }, 500);
    
    // setTimeout(() => {
      
    // }, 1000);
    // const data_send = {};
    // this._api.historialObjeto(data_send).subscribe((data:any)=>{
    //   console.log(data)
    // });
  }

  ionViewDidLoad() {
   
    // this.leafletMap();
    // setTimeout(() => {
    //   console.log("OKOKOK");
    //   this.map.invalidateSize();
    // }, 500);
  }

  getObjeto(){

    // const loading = await this.loadingCtrl.create({
    //   message: 'Ca...',
    //   duration: 3000,
    //   spinner: 'circles',
    // });

    // this._api.getObjeto( {imei:this.imei} ).subscribe((data:any)=>{
    //   console.log( data );
    //   this.objeto = data.data[0];
    // });
  }

  segmentChanged( event:any ){
    console.log( event );
  }

  back(){
    this.navCtrl.back();
  }

  // leafletMap(){

  //   let osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
	// 	let osmAttrib = '&copy; OSM - contributors';
	// 	let osm  = Leaflet.tileLayer(osmUrl, { maxZoom: 21, attribution: osmAttrib });
  //   this.map = Leaflet.map('map', { attributionControl: false, center: [this.lat, this.lng], zoom: 16 });

  //   const here = {
  //     id: '6AJleReU2wy5FIYdcHUZ',
  //     code: '2sYGPV-IeanNImtVlcmNpw'
  //   }
  //   const style = 'normal.day';

  //   Leaflet.control.layers({
  //     'OSM': osm.addTo(this.map),
  //     "Humanitarian": Leaflet.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { attribution: 'Map &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> | Tiles &copy; <a href=\"http://hot.openstreetmap.org\">Humanitarian OSM Team</a>', minZoom: 0, maxZoom: 20 }),
  //     'HERE': Leaflet.tileLayer(`https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}&ppi=320`,{
  //       attribution:"HERE",
  //       maxZoom:19
  //     }),
  //       'HERE HYBRID': Leaflet.tileLayer(`https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}`,{
  //       attribution: 'HERE hybrid',
  //       maxZoom: 19
  //     }),
  //     'Google Streets': Leaflet.tileLayer('http://www.google.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}', {
	//       attribution: 'google',
	//       maxZoom: 20,
  //     }),           
  //     "Google Traffic": Leaflet.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
  //         attribution: 'google',
  //         maxZoom: 20,
  //         minZoom: 2,
  //         subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  //     }),
  //     "Google Hybrid": Leaflet.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
  //         attribution: 'google',
  //         maxZoom: 20
  //     })  
  //   }).addTo(this.map);

  // }

}
